<template>
  <div class="innerContent"
    style="
      height: calc(100vh);
      width: 100%;
      min-width: 85%;
      max-width: 100%;
      background: var(--v-greyRaised-base) !important;
    ">
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3">
            <v-card color="modal" class="my-0 py-0" style="border-radius: 20px">
              <v-card-text class="my-0 py-0">
                <v-list dense subheader>
                  <v-subheader>
                    <v-icon class="mr-2">account_balance</v-icon>
                    Account Setup here
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-autocomplete
                    v-if="accountingContacts.length > 0"
                    hide-details
                    label="Linked Accounting Contact"
                    v-model="company.accountingId"
                    dense
                    outlined
                    clearable
                    :items="accountingContacts"
                    item-text="name"
                    item-value="id"
                    return-object
                    @change="saveAccountingSetting"
                  >
                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item
                        style="height: 30px"
                        @click="addAccountingContact"
                      >
                        <v-list-item-content class="text-center">
                          <v-list-item-title class="text-center">
                            <v-btn color="primary" small text>Create New</v-btn>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon color="grey">currency_exchange</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ company.currency }}</v-list-item-title>
                      <v-list-item-subtitle>Default Currency</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-autocomplete
                        style="width: 100px"
                        outlined
                        dense
                        hide-details
                        :items="currencies"
                        v-model="company.currency"
                        @change="saveRelationship"
                      ></v-autocomplete>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-action>
                      <v-icon :color="getClientStatusColor(company.clientStatus)">verified</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Client Status</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-menu
                        offset-y
                        :disabled="$store.state.user.tagId !== 1 && $store.state.user.tagId !== 2"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            v-bind="attrs"
                            style="font-size: 12px"
                            v-on="on"
                            outlined
                            :loading="savingClientStatus"
                            :color="getClientStatusColor(company.clientStatus)"
                          >
                            {{ company.clientStatus }}
                            <v-icon small right>arrow_drop_down</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item
                            v-for="(status, index) in clientStatusItems"
                            :key="index"
                            @click="updateClientStatus(status)"
                          >
                            <v-list-item-action>
                              <v-icon :color="getClientStatusColor(status)">fiber_manual_record</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>{{ status }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="editAccountReference = true">
                    <v-list-item-action>
                      <v-icon color="grey">tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Account Reference</v-list-item-title>
                      <v-list-item-subtitle>
                        <span v-if="!company.accountReference">No Account Reference</span>
                        <span v-else>{{ company.accountReference }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-group v-if="company.paymentTerms && Object.keys(company.paymentTerms).length > 0">
                    <!-- @click="openEditPaymentTerm" -->
                     <template v-if="company.paymentTerms" v-slot:activator>

                        <v-list-item-action>
                          <v-icon color="grey">gavel</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Payment Terms</v-list-item-title>
                          <v-list-item-subtitle>
                            <span v-if="!company.paymentTerms">No Payment Terms</span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                     </template>
                     <div v-if="company.paymentTerms">

                       <v-list-item v-for="(group, index) in company.paymentTerms" :key="index">
                          <v-list-item-action>
                            <v-icon color="grey">text_snippet</v-icon>
                          </v-list-item-action>
                         <v-list-item-content class="clickable" @click="openEditPaymentTerm(group, index)">
                           <v-list-item-title class="text-wrap">
                             {{ (group.map((a) => a.sentence)).join(', ') }}
                           </v-list-item-title>
                         </v-list-item-content>
                         <v-list-item-action-text>
                          <v-btn icon :loading="removingPaymentTermGroup">
                            <v-icon small @click="removePaymentTermsGroup(group, index)" color="red">delete</v-icon>
                          </v-btn>
                         </v-list-item-action-text>
                       </v-list-item>
                       <v-row justify="center">
                        <v-col cols="12" sm="2" class="d-flex justify-center align-center text-center">
                          <v-btn
                            small
                            @click.stop="openEditPaymentTerm()"
                            color="primary"
                            class="transparent-button"
                            rounded
                            >
                            <v-icon left>add_circle</v-icon> Payment Terms
                          </v-btn>
                        </v-col>
                      </v-row>
                     </div>
                     
                  </v-list-group>
                  <v-list-item v-else>
                    <v-list-item-action>
                          <v-icon color="grey">gavel</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Payment Terms</v-list-item-title>
                          <v-list-item-subtitle>
                            <span>No Payment Terms</span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action-text>
                          <v-btn
                            small
                            @click.stop="openEditPaymentTerm()"
                            color="primary"
                            class="transparent-button align-center"
                            rounded
                            >
                            <v-icon>add_circle</v-icon>
                          </v-btn>
                        </v-list-item-action-text>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Allow Credit Limit
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        color="blue"
                        v-model="company.allowCreditLimit"
                        @click="saveRelationship"
                      ></v-switch>

                      <!-- <v-btn
                        small
                        v-if="company.allowCreditLimit"
                        @click.stop="showAddCreditLimitModal = true"
                        color="primary"
                        class="transparent-button"
                      >
                        <v-icon left>add_circle</v-icon> Add Credit Guarantor
                      </v-btn>

                      <v-btn
                        small
                        v-if="company.allowCreditLimit"
                        @click.stop="showSelfGuarantorModal = true"
                        color="primary"
                        class="outlined-button"
                      >
                      <v-icon left>add_circle</v-icon>  Self Guarantor
                      </v-btn> -->


                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item v-if="company.allowCreditLimit">
                    <v-list-item-content>
                      <v-list-item-title>Credit Limit</v-list-item-title>
                      <v-list-item-subtitle>
                        <span v-if="!company.creditLimit">No Credit Limit</span>
                        <span v-else>Total {{ company.creditLimitCurrency }} {{ formatNumber(company.creditLimit) }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  
                  <v-slider
                  v-model="company.warningPercentage"
                  :min="0"
                  :max="100"
                  label="Credit Warning"
                  thumb-label="always"
                  @change="saveWarningPercentage"
                  v-if="company.allowCreditLimit"
                  class="small-slider-label"
                ></v-slider>
                <v-subheader class="d-flex justify-space-between">Total Credit issued: 
                  <span class="text-right">
                   {{ getSymbol(company.currency) }} {{ formatNumber(company.totalCreditLimit) }}
                  </span>
                </v-subheader>
                  <!-- <v-list-item v-if="company.allowCreditLimit">
                  <v-list-item-content>
                    <v-list-item-title>
                      <strong>Total Credit Limit:</strong> {{ company.creditLimitCurrency }} {{ formatNumber(total) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->

                <!-- <v-list-item v-if="company.allowCreditLimit">
                  <v-list-item-content>
                    <v-list-item-title>
                      <strong>Total Credit Limits:</strong>
                    </v-list-item-title>
                    <v-list-item-subtitle v-for="(total, currency) in totalCreditLimits" :key="currency">
                      {{ currency }}: {{ formatNumber(total) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item> -->


                  <!-- <v-list-item v-for="(credit, index) in creditLimits" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>
                      <strong>Policy Number:</strong> {{ credit.policyNumber }}
                    </v-list-item-title>
                    <v-list-item-title>
                      <strong>Currency:</strong> {{ credit.creditCurrency }}
                    </v-list-item-title>
                    <v-list-item-title>
                      <strong>Amount:</strong> {{ formatNumber(credit.creditLimit) }}
                    </v-list-item-title>
                    <v-list-item-title>
                      <strong>Organisation:</strong> {{ credit.organisation }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon small @click.stop="removeCreditLimit(index)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item> -->

                <!-- <v-list-item v-for="(credit, index) in selfGuarantorLimits" :key="'self-guarantor-' + index">
                  <v-list-item-content>
                    <v-list-item-title>
                      <strong>Policy Number:</strong> {{ credit.policyNumber }}
                    </v-list-item-title>
                    <v-list-item-title>
                      <strong>Currency:</strong> {{ credit.currency }}
                    </v-list-item-title>
                    <v-list-item-title>
                      <strong>Amount:</strong> {{ formatNumber(credit.amount) }}
                    </v-list-item-title>
                    <v-list-item-title>
                      <strong>Credit Guarantor: </strong> {{ credit.organisation }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->
                <v-divider></v-divider>
                <v-list class="ma-0 pa-0">
                  <draggable v-model="creditLimits" @end="onDragEnd" tag="div" :handle="'.drag-handle'">
                    <div v-for="(credit, index) in creditLimits" :key="credit.rank" >
                      <v-list-item>
                        <v-list-item-content class="drag-handle">
                          <v-list-item-title class="d-flex justify-space-between ma-0 pa-0" :loading="loadingRanks">
                            <!-- <v-text-field
                              type="number"
                              label="Rank"
                              dense
                              v-model="credit.shadowRank"
                              outlined
                              @change="updateRank(credit, index, credit.shadowRank)">
                            </v-text-field> -->
                            <v-btn text class="ma-0 pa-0" small dense hide-details :loading="loadingRanks">
                              Rank: {{ credit.rank  }}
                            </v-btn>
                            <span>

                            </span>
                      
                          </v-list-item-title>
                          <v-list-item-title class="d-flex justify-space-between">
                            <span>
                              Policy No:
                            </span>
                            <span class="text-right">
                              {{ credit.policyNumber }}
                            </span>
                          </v-list-item-title>
                          <v-list-item-subtitle class="d-flex justify-space-between">
                            <span><strong>Limit:</strong></span>
                            <span class="text-right">
                              <strong>
                                {{ getSymbol(credit.creditCurrency) }}{{ formatNumber(credit.limit) }}
                              </strong>
                            </span>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="d-flex justify-space-between">
                            <span>Monthly Premium:</span> 
                            <span class="text-right">
                              {{ getSymbol(credit.creditCurrency) }}{{ formatNumber(credit.monthlyPremium) }}
                            </span>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="d-flex justify-space-between">
                            <v-chip small v-if="credit.creditProvider">
                              <v-avatar
                                left
                                :color="credit.creditProvider.logo ? 'white' : 'secondary'"
                                size="24"
                              >
                                <v-img
                                  v-if="credit.creditProvider.logo"
                                  :src="credit.creditProvider.logo"
                                  contain
                                ></v-img>
                                <h3 v-else>
                                  {{ credit.creditProvider.name.charAt(0) }}
                                </h3>
                              </v-avatar>
                              {{ credit.creditProvider.alias ? credit.creditProvider.alias : credit.creditProvider.name }}
                            </v-chip>
                          </v-list-item-subtitle>

                        <!-- <v-progress-linear class="mb-0 mt-2 py-0"
                        v-if="loadingRanks"
                          indeterminate
                        ></v-progress-linear> -->
                        </v-list-item-content>
                        <v-list-item-action-text class="ml-2 pl-2">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-action class="mr-1 pr-1">
                                <v-btn icon x-small @click="openEditCreditLimitModal(credit, index)" v-bind="attrs" v-on="on">
                                  <v-icon>edit</v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </template>
                            <span>Edit </span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-action class="ml-1 pl-1">
                                <v-btn color="red" icon x-small @click="removeCreditLimit(index)" v-bind="attrs" v-on="on">
                                  <v-icon>delete</v-icon>
                                </v-btn>
                              </v-list-item-action> 
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </v-list-item-action-text>
                      </v-list-item>
                      <v-divider v-if="index < creditLimits.length - 1" :key="index"></v-divider>
                    </div>
                  </draggable>
                </v-list>
                <!-- <v-list-item v-for="(credit, index) of creditLimits" :key="index">
                  <v-list-item>

                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>Rank:</strong> {{ index + 1 }}
                      </v-list-item-title>
                      <v-list-item-title>
                        {{ credit.policyNumber }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Limit: <span class="text-right">
                          {{ getSymbol(credit.creditCurrency) }}{{ formatNumber(credit.limit) }}
                        </span> 
                        <strong>Currency:</strong> {{ credit.creditCurrency }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                      <strong>Monthly Premium:</strong> {{ getSymbol(credit.creditCurrency) }}{{ formatNumber(credit.limit) }}
                      </v-list-item-subtitle>
  
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                      <v-list-item-title>
                        <strong  v-bind="attrs" v-on="on"  >Credit Guarantor:</strong> {{ credit.organisation }}
                      </v-list-item-title>
                    </template>
                  <span> {{ credit.organisation }} </span>
                  </v-tooltip>
                </v-list-item-content>
                <v-list-item-action-text>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-action>
                        <v-btn icon small @click.stop="removeCreditLimit(index)" v-bind="attrs" v-on="on">
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-list-item-action> 
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                  
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-action>
                        <v-btn icon small @click.stop="openEditCreditLimitModal(credit, index)" v-bind="attrs" v-on="on" >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                    <span>Edit </span>
                  </v-tooltip>
                  
                </v-list-item-action-text>
                  </v-list-item>
                  <v-divider></v-divider>
              </v-list-item> -->
              <v-divider></v-divider>
              <v-row justify="center">
                        <v-col cols="12" sm="2" class="d-flex justify-center align-center text-center">
                          <v-btn
                            small
                            v-if="company.allowCreditLimit"
                            @click.stop="openAddCreditDialog"
                            color="primary"
                            class="transparent-button"
                            rounded
                            >
                            <v-icon left>add_circle</v-icon> Payment Terms
                          </v-btn>
                        </v-col>
                      </v-row>
              <!-- <v-list class="ma-0 pa-0">
                <v-list-item class="ma-0 pa-0">
                  <v-list-item-content class="ma-0 pa-0">
                    <v-btn
                    small
                    v-if="company.allowCreditLimit"
                    @click.stop="openAddCreditDialog"
                    color="primary"
                    class="transparent-button align-center"
                    rounded
                    >
                    <v-icon left>add_circle</v-icon> Credit Guarantor
                  </v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-list> -->

                
              
              <!-- <v-btn
              small
              v-if="company.allowCreditLimit"
              @click.stop="showSelfGuarantorModal = true"
              color="primary"
              class="outlined-button"
              rounded
              >
              <v-icon left>add_circle</v-icon>  Self Guarantor
            </v-btn> -->
            
            
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ getSymbol(company.currency) }} {{ formatNumber(company.accountsReceivableTotal) }}
                </v-list-item-title>
                <v-list-item-subtitle>Accounts Receivable</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title :style="{ color: company.accountsReceivableOverdue > 0 ? 'red' : '' }">
                  {{ getSymbol(company.currency) }} {{ formatNumber(company.accountsReceivableOverdue) }}
                </v-list-item-title>
                <v-list-item-subtitle>Overdue</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ getSymbol(company.currency) }} {{ formatNumber(company.accountsPayableTotal) }}
                </v-list-item-title>
                <v-list-item-subtitle>Accounts Payable</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title :style="{ color: company.accountsPayableOverdue > 0 ? 'red' : '' }">
                  {{ getSymbol(company.currency) }} {{ formatNumber(company.accountsPayableOverdue) }}
                </v-list-item-title>
                <v-list-item-subtitle>Overdue</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
                  
                  <template>
                  <v-list-item v-if="company.allowCreditLimit">
                    <v-list-item-content>
                      <div class="gauge-container ">
                        <GaugeChart 
                          :totalCreditLimit="!loadingAccountState ? parseFloat(company.totalCreditLimit): 10" 
                          :creditUsed="!loadingAccountState ? parseFloat(company.accountsPayableTotal): 0"
                          :ranks="!loadingAccountState ? creditLimits.map((x) => x.rank) : [1]"
                          :warningPercentage="company.warningPercentage"
                          :credits="creditLimits"
                        />
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              
                <!-- <v-list-item v-if="company.allowCreditLimit">
                  <v-list-item-content>
                    <GaugeValue 
                      :creditLimit="parseFloat(company.creditLimit)" 
                      :creditUsed="parseFloat(company.creditUsed)"
                      :creditCurrency="company.creditLimitCurrency" 
                    />
                  </v-list-item-content>
                </v-list-item> -->

                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="9">
            <v-card
              :loading="loadingBilling"
              class="my-1"
              style="border-radius: 20px; background-color: var(--v-toolbar-lighten1) !important;"
            >
              <v-card-text class="my-0 py-0">
                <v-list subheader dense>
                  <v-subheader style="font-size: 16px">
                    <v-icon color="grey" class="mr-2">payment</v-icon>
                    Costing Sheet
                  </v-subheader>
                  <v-divider></v-divider>
                  <CostingSheet
                    :financeChargeItems="financeChargeItems"
                    :baseCurrency="company.currency"
                    :organisationRelationshipId="company.id"
                    :billingItems="billingItems"
                    :disableDate="true"
                    :organisationId="company.relatedOrganisationId"
                  />
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Account Reference -->
    <v-dialog v-model="editAccountReference" width="500px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Account Reference</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="primary" :loading="savingRelationship" @click="saveRelationship">
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn text @click="editAccountReference = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="Account Reference"
            v-model="company.accountReference"
            outlined
            clearable
            autofocus
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editPaymentTerms" width="1200px">
      <!-- <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Edit Payment Terms</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="secondary" @click="saveRelationship" :loading="savingRelationship">
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn icon @click="editPaymentTerms = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-textarea v-model="company.paymentTerms" outlined rows="10" auto-grow></v-textarea>
        </v-card-text>
      </v-card> -->
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            <span v-if="paymentTerms.some(term => term.id)">
              Edit
            </span>
            <span v-else>
              Add
            </span>
            Payment Terms</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="secondary" :disabled="inValidPaymentTerms" @click="savePaymentTerms" :loading="savingPaymentTerms">
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn text @click="closeEditPaymentTerm">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <draggable v-model="paymentTerms" @end="onPaymentTermMove" tag="div" :handle="'.payment-handle'">
              <div v-for="(paymentTerm, index) in paymentTerms" :key="paymentTerm.rank">
                <v-list-item>
                  <v-list-item-action class="d-flex justify-center" align="center">
                    <v-chip medium class="payment-handle">
                      # {{paymentTerm.rank}}
                    </v-chip>
                  </v-list-item-action>
                  <v-list-item-content class="mx-1 px-1" v-if="!paymentTerm.showSentence">
                    <v-row>
                      <v-col cols="12" sm="2" class="mt-1 pt-1 pb-0 mt-0 mx-1 px-1" v-if="paymentTerm.calculationType !== 'PERCENTAGE'">
                        <v-text-field
                        outlined
                        hide-details
                        dense
                        v-model="paymentTerm.amount"
                        label="Payment">
          
                        </v-text-field>
                      </v-col>
                      <v-col v-else cols="12" sm="3" class="mt-1 pt-1 pb-0 mt-0 ml-1 mr-2 pl-1 pr-1">
                        <v-text-field
                          outlined
                          hide-details
                          dense
                          type="Percentage"
                          suffix="%"
                          v-model="paymentTerm.calculationValue"
                          label="Value">
            
                          </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="1" class="mt-1 pt-1 pb-0 mt-0 ml-0 mr-1 pl-0 pr-1"
                      v-if="paymentTerm.calculationType !== 'PERCENTAGE'">
                      <v-autocomplete
                      outlined
                        hide-details
                        dense
                        v-model="paymentTerm.currency"
                        :items="currencies"
                        label="Currency">
                        
                      </v-autocomplete>
                    </v-col>
                      <v-col cols="12" sm="2" class="mt-1 pt-1 pb-0 mt-0 ml-0 mr-1 pl-0 pr-1">
                        <v-autocomplete
                        outlined
                        hide-details
                        dense
                        v-model="paymentTerm.calculationType"
                        :items="['FIXED','PERCENTAGE']"
                        label="Type">
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="1" class="mt-1 pt-1 pb-0 mt-0 ml-0 mr-1 pl-0 pr-1">
                        <v-text-field
                        disabled
                        outlined
                        dense
                        hide-details
                        value="ON">

                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="1" class="mt-1 pt-1 pb-0 mt-0 ml-0 mr-1 pl-0 pr-1">
                        <v-autocomplete
                        outlined
                        hide-details
                        dense
                        v-model="paymentTerm.trigger"
                        label="Trigger"
                        :items="['ETA','ATA','ETD','ATD','INVOICE']">
            
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="1" class="mt-1 pt-1 pb-0 mt-0 ml-0 mr-1 pl-0 pr-1">
                        <v-autocomplete
                        outlined
                        hide-details
                        dense
                        v-model="paymentTerm.timeRelative"
                        :items="['+','-']"
                        label="+/-">
            
                        </v-autocomplete>
          
                      </v-col>
                      <v-col cols="12" sm="1" class="mt-1 pt-1 pb-0 mt-0 ml-0 mr-1 pl-0 pr-1">
                        <v-text-field
                        outlined
                        hide-details
                        dense
                        v-model="paymentTerm.timeValue"
                        label="Value">
            
                        </v-text-field>
          
                      </v-col>
                      <v-col cols="12" sm="2" class="mt-1 pt-1 pb-0 mt-0 ml-0 mr-1 pl-0 pr-1">
                        <v-autocomplete
                        outlined
                        hide-details
                        dense
          
                        v-model="paymentTerm.timeUnit"
                        :items="['DAYS','WEEKS']"
                        label="Increment">
            
                        </v-autocomplete>
          
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                  <v-list-item-content class="mx-1 px-1" v-else>
                    {{ paymentTerm.sentence }}
                  </v-list-item-content>
                  <v-list-item-action-text>
                    <v-btn @click="showSentence(index, paymentTerm)" icon>
                      <v-icon color="primary">
                        visibility
                      </v-icon>
                    </v-btn>
                    <v-btn @click="removePaymentTerm(index, paymentTerm)" icon>
                      <v-icon color="red">
                        delete
                      </v-icon>
                    </v-btn>
                  </v-list-item-action-text>
                </v-list-item>
              </div>
            </draggable>
          </v-list>
          <v-row justify="center" v-if="paymentTerms.length && paymentTerms.length > 0">
            <v-col cols="12" sm="2" class="d-flex justify-center align-center text-center">
              <v-btn
              :disabled="paymentTerms.length && paymentTerms.length >= 3"
                small
                @click.stop="addPaymentTerm"
                color="primary"
                class="transparent-button"
                rounded
                >
                <v-icon left>add_circle</v-icon> Payment Term
              </v-btn>
            </v-col>
          </v-row>
          <!-- <v-list>

            <v-list-item class="ma-0 pa-0">
              <v-list-item-content class="ma-0 pa-0">
                <v-btn
                small
                v-if="company.allowCreditLimit"
                @click.stop="addPaymentTerm"
                color="primary"
                class="transparent-button align-center"
                rounded
                outlined
                :style="{ width: '200px' }"
                >
                <v-icon left>add_circle</v-icon> Payment Term
              </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list> -->
          
          <!-- <v-textarea v-model="company.paymentTerms" outlined rows="10" auto-grow></v-textarea> -->
        </v-card-text>
      </v-card>

    </v-dialog>

    <!-- <v-dialog v-model="updateCreditLimitModal" width="600px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Update Credit Limit</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="secondary" @click="saveRelationship" :loading="savingRelationship">
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn icon @click="updateCreditLimitModal = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4">
              <v-autocomplete
                label="Currency"
                outlined
                dense
                hide-details
                :items="currencies"
                v-model="company.creditLimitCurrency"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                label="Credit Limit"
                v-model="company.creditLimit"
                outlined
                clearable
                dense
                autofocus
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- Modal for editing the credit limit -->
    <v-dialog v-model="showEditCreditLimitModal" width="600px">
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>
        <span v-if="editCreditLimit.id">
          Edit
        </span>
        <span v-else>
          Add
        </span>
        Credit Limit</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn :disabled="!editCreditLimit.creditCurrency || !editCreditLimit.limit || !editCreditLimit.policyNumber || !editCreditLimit.calculationType || (!editCreditLimit.isSelf && !editCreditLimit.creditProviderRelationshipId) || (editCreditLimit.calculationType === 'PERCENTAGE' && !editCreditLimit.premiumPercentage) || (editCreditLimit.calculationType === 'FIXED' && !editCreditLimit.monthlyPremium)"  icon color="secondary" @click="saveCreditLimits()" :loading="savingRelationship">
        <v-icon>save</v-icon>
      </v-btn>
      <v-btn icon @click="closeEditCreditLimit">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" class="my-0 py-0 px-1">
            <v-autocomplete v-if="!editCreditLimit.isSelf"
              label="Credit Provider*"
              outlined
              dense
              hide-details
              class="py-1 my-1"
              :items="organisations"
              v-model="editCreditLimit.creditProviderRelationshipId"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
            <v-text-field v-else
              label="Credit Provider*"
              outlined
              hide-details
              dense
              class="py-1 my-1"
              disabled
              v-model="company.organisation.name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0 px-1">
            <v-text-field
              label="Policy Number*"
              v-model="editCreditLimit.policyNumber"
              outlined
              clearable
              dense
              hide-details
              class="py-1 my-1"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0 px-1">
            <v-checkbox
            dense
            label="Own Company Credit"
            class="py-0 my-0 px-2"
            v-model="editCreditLimit.isSelf"></v-checkbox>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="my-1 py-1">
          <v-col cols="12" sm="6" class="my-0 py-0 px-1">
            <v-autocomplete
              label="Currency*"
              outlined
              dense
              :items="currencies"
              v-model="editCreditLimit.creditCurrency"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0 px-1">
            <v-text-field
              label="Credit Limit*"
              v-model="editCreditLimit.limit"
              outlined
              clearable
              dense
              autofocus
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0 px-1">
            <v-select
              label="Premium Type*"
              v-model="editCreditLimit.calculationType"
              :items="['FIXED', 'PERCENTAGE']"
              hide-details
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0 px-1">
            <v-text-field v-if="editCreditLimit.calculationType === 'PERCENTAGE'"
              label="Monthly Premium Percentage*"
              v-model="editCreditLimit.premiumPercentage"
              outlined
              clearable
              hide-details
              dense
            ></v-text-field>
            <v-text-field v-else
              label="Monthly Premium*"
              v-model="editCreditLimit.monthlyPremium"
              outlined
              clearable
              hide-details
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</v-dialog>


      <!-- Self Guarantor modal -->
      <!-- <v-dialog v-model="showSelfGuarantorModal" width="600px">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>Self Guarantor Credit Limits</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="secondary" @click="saveSelfGuarantorLimits" :loading="savingRelationship">
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn icon @click="showSelfGuarantorModal = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row v-for="(credit, index) in selfGuarantorLimits" :key="index" class="mb-4">
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    label="Currency"
                    outlined
                    dense
                    hide-details
                    :items="currencies"
                    v-model="credit.currency"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Credit Limit"
                    v-model="credit.limit"
                    outlined
                    clearable
                    dense
                    autofocus
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Monthly Premium"
                    v-model="credit.monthlyPremium"
                    outlined
                    clearable
                    dense
                    autofocus
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                 </template>
                 <span>Client's monthly credit repayment</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Policy Number"
                    v-model="credit.policyNumber"
                    outlined
                    clearable
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Organisation"
                    v-model="credit.organisation"
                    outlined
                    dense
                    :readonly="true"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" class="text-right">
                  <v-btn icon small @click.stop="removeSelfGuarantorLimit(index)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-btn @click="addSelfGuarantorLimit">+ Add Another Credit Limit</v-btn>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog> -->

    <!-- New Modal for Adding Additional Credit Limits -->
    <v-dialog v-model="showAddCreditLimitModal" width="600px">
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title> Add Credit Limits </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon color="secondary" @click="saveCreditLimits()" :loading="savingRelationship">
        <v-icon>save</v-icon>
      </v-btn>
      <v-btn icon @click="closeAddCreditLimit">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row v-for="(credit, index) in newCreditLimits" :key="index" class="mb-1">
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-autocomplete
              label="Currency"
              outlined
              dense
              hide-details
              :items="currencies"
              v-model="credit.currency"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-text-field
              label="Credit Limit"
              v-model="credit.limit"
              outlined
              clearable
              dense
              autofocus
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-text-field
              label="Policy Number"
              v-model="credit.policyNumber"
              outlined
              clearable
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-select
              label="Premium Type"
              v-model="credit.calculationType"
              :items="['FIXED', 'PERCENTAGE']"

              outlined
              clearable
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
            <v-text-field v-if="credit.calculationType === 'PERCENTAGE'"
              label="Monthly Premium Percentage"
              v-model="credit.premiumPercentage"
              outlined
              clearable
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
            <v-text-field v-else
              label="Monthly Premium"
              v-model="credit.monthlyPremium"
              outlined
              clearable
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <span>Client's monthly credit repayment</span>
          </v-tooltip>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-autocomplete
              label="Credit Provider"
              outlined
              dense
              hide-details
              :items="organisations"
              v-model="credit.creditProviderRelationshipId"
              item-text="name"
              item-value="id"
              @change="onOrganisationChange(index, $event)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" class="text-right">
            <v-btn v-if="newCreditLimits.length > 1" icon small @click.stop="removeNewCreditLimit(index)">
              <v-icon>delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-btn @click="addNewCreditLimit">+ Add Another Credit Limit</v-btn>
      </v-container>
    </v-card-text>
  </v-card>
</v-dialog>


    <v-dialog v-model="accountingContactModal" width="900px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Create Contact</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="primary" :disabled="!accountingContact.name" @click="saveAccountingContact" :loading="savingAccountingContact">
            <v-icon> save </v-icon>
          </v-btn>
          <v-btn text @click="accountingContactModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Name*"
                  v-model="accountingContact.name"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  label="Account Reference"
                  v-model="accountingContact.accountReference"
                  outlined
                  dense
                ></v-text-field>
                <v-autocomplete
                  label="Currency*"
                  v-model="accountingContact.currency"
                  outlined
                  dense
                  :items="currencies"
                ></v-autocomplete>
                <v-text-field
                  label="Tax Number"
                  v-model="accountingContact.taxNumber"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  label="Email Address"
                  v-model="accountingContact.emailAddress"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-if="accountingContact && accountingContact.address">
                <v-subheader>
                  <v-icon small class="mr-2">location_on</v-icon>
                  Address
                </v-subheader>
                <v-text-field
                  dense
                  label="Address Line 1"
                  hide-details
                  class="my-1"
                  v-model="accountingContact.address.addressLine1"
                  outlined
                ></v-text-field>
                <v-text-field
                  dense
                  label="Address Line 2"
                  hide-details
                  class="my-2"
                  v-model="accountingContact.address.addressLine2"
                  outlined
                ></v-text-field>
                <v-text-field
                  dense
                  label="Address Line 3"
                  hide-details
                  class="my-2"
                  v-model="accountingContact.address.addressLine3"
                  outlined
                ></v-text-field>
                <v-text-field
                  dense
                  label="City"
                  hide-details
                  class="my-2"
                  v-model="accountingContact.address.city"
                  outlined
                ></v-text-field>
                <v-text-field
                  dense
                  label="Region"
                  hide-details
                  class="my-2"
                  v-model="accountingContact.address.region"
                  outlined
                ></v-text-field>
                <v-text-field
                  dense
                  label="Postal Code"
                  hide-details
                  class="my-2"
                  v-model="accountingContact.address.postalCode"
                  outlined
                ></v-text-field>
                <v-text-field
                  dense
                  label="Country"
                  hide-details
                  class="my-2"
                  v-model="accountingContact.address.country"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CostingSheet from "@/components/Finance/components/CostingSheet";
import * as CurrencyCodes from "currency-codes";
import getSymbolFromCurrency from "currency-symbol-map";
import GaugeChart from "../Charts/GaugeChart.vue";
import draggable from "vuedraggable"

export default {
  props: ["company", "teams"],
  components: {
    CostingSheet,
    draggable,
    GaugeChart,
  },
  data() {
    return {
      accountingContacts: [],
      accountingContact: {},
      accountingContactModal: false,
      billingItems: [],
      clientStatusItems: ["GOOD STANDING", "INACTIVE", "ON HOLD", "COD"],
      currencies: CurrencyCodes.codes(),
      editPaymentTerms: false,
      editAccountReference: false,
      loadingBilling: false,
      financeChargeItems: [],
      savingAccountingContact: false,
      savingClientStatus: false,
      savingRelationship: false,
      updateCreditLimitModal: false,
      showAddCreditLimitModal: false,
      showSelfGuarantorModal: false, // New data property for self guarantor modal
      creditLimits: [], // New data property for storing credit limits
      newCreditLimDefault:[
        { currency: "", amount: 0, policyNumber: "", monthlyPremium: 0, organisation: null, rank: null },
      ], // Array to store new credit limits being added
      newCreditLimits: [], // Array to store new credit limits being added
      selfGuarantorLimits: [
        { currency: "", amount: 0, policyNumber: "", monthlyPremium: 0, organisation: this.$store.state.currentOrg.name, rank: null },
      ], // Array to store self guarantor limits
      organisations: [], // Array to store fetched organisations
      totalCreditLimits: {}, // New data property for total credit limits by currency
      total: 0, // Total for the desired currency

      showEditCreditLimitModal: false,
      editCreditLimit: {
        // currency: '',
        // amount: 0,
        // policyNumber: '',
        // organisation: null,
        // monthlyPremium: 0,
        // warningPercentage: localStorage.getItem('warningPercentage') ? parseInt(localStorage.getItem('warningPercentage')) : 50 
      },
      editCreditLimitDefault: {
        creditCurrency: this.company.currency,
        amount: 0,
        creditProviderRelationshipId: null,
        creditProviderId: null,
        policyNumber: '',
        calculationType: 'FIXED',
        premiumPercentage: 0,
        organisation: null,
        monthlyPremium: 0,
        warningPercentage: localStorage.getItem('warningPercentage') ? parseInt(localStorage.getItem('warningPercentage')) : 50 
      },
      editIndex: null,
      loadingRanks: false,
      loadingAccountState: false,
      paymentTerm: {},
      paymentTerms: [],
      relationshipPaymentTerms: [],
      savingPaymentTerms: false,
      removingPaymentTermGroup: false,
    };
  },
  computed: {
    inValidPaymentTerms() {
      for (const term of this.paymentTerms) {
        if (term.calculationType === "PERCENTAGE") {
          if (!term.calculationValue || !term.trigger || !term.timeRelative || !term.timeUnit || !term.timeValue) {
            return true; 
          }
        } else {
          if (!term.calculationType) {
            return true; 
          } else if (!term.amount || !term.currency || !term.trigger || !term.timeRelative || !term.timeUnit || !term.timeValue) {
            return true;
          }
        }
      }
      return false; 
    }
    // computedRanks() {
    //   const ranks = this.creditLimits.map((_, index) => `Rank ${index + 1}`);
    //   console.log("Computed Ranks:", ranks);
    //   return ranks;
    // }
  },
  watch: {
    creditLimits: {
      handler(newLimits) {
        // console.log("Updated Credit Limits:", newLimits);
        // this.updateRanks();
      },
      deep: true
    },
    warningPercentage(newVal) {
      this.saveWarningPercentage(newVal);
      // this.updateGaugeChart();
    }
  },
  created() {
    this.getAccountingContacts();
    this.getFinanceChargeItems();
    this.loadBillingItems(this.company.id);
    this.fetchOrganisations(); // Fetch the organisations
    this.getCreditLimits(this.company.id); // Fetch the credit limits
    this.getAccountState(this.company.id)
    // Initialize creditLimits from company data if it exists
    if (this.company.creditLimits) {
      this.creditLimits = this.company.creditLimits;
      this.sortCreditLimits();
    }

    const savedWarningPercentage = localStorage.getItem("warningPercentage");
    if (savedWarningPercentage !== null) {
      this.warningPercentage = parseInt(savedWarningPercentage, 10);
    }
  },
  methods: {
    
    async removePaymentTermsGroup(group, index) {
      try{
        this.removingPaymentTermGroup = true
        let groupId = group[0].group
        let response = await this.$API.removePaymentTermsGroup(this.company.id, {group: groupId})
        let newTerms = {...this.company.paymentTerms}
        delete newTerms[groupId]
        this.$emit('updatePaymentTerms', {...newTerms})
        this.removingPaymentTermGroup = false
      }catch(e) {
        console.log(e)
      }
    },
    async savePaymentTerms() {
      // if()
      this.savingPaymentTerms = true
      for(let term of this.paymentTerms) {
        term.sentence = `${term.calculationType === "PERCENTAGE" ? term.calculationValue + '%' : this.getSymbol(term.currency)+term.amount} on ${term.trigger} ${term.timeRelative} ${term.timeValue} ${term.timeUnit}`
      }
      if(this.paymentTerms.some(x => x.id)) {
        let response = await this.$API.updatePaymentTerms(this.company.id, this.paymentTerms)
        this.paymentTerms = response
        let updatedTerms = this.company.paymentTerms
        updatedTerms[response[0].group] = response
        this.$emit('updatePaymentTerms', updatedTerms)
        this.relationshipPaymentTerms.push(response)
      } else {
        let response = await this.$API.createPaymentTerms(this.company.id, this.paymentTerms)
        this.paymentTerms = response
        let newTerms = {}
        newTerms[response[0].group] = [...response]
        this.$emit('updatePaymentTerms', {...this.company.paymentTerms, ...newTerms})
        this.relationshipPaymentTerms.push({...this.company.paymentTerms, ...newTerms})
      }
      this.savingPaymentTerms = false
      this.editPaymentTerms = false
      this.paymentTerms = []
    },
    showSentence(index, paymentTerm) {
      this.paymentTerms[index].sentence = `${this.paymentTerms[index].calculationType === "PERCENTAGE" ? this.paymentTerms[index].calculationValue + '%' : this.getSymbol(this.paymentTerms[index].currency)+this.paymentTerms[index].amount} on ${this.paymentTerms[index].trigger} ${this.paymentTerms[index].timeRelative}${this.paymentTerms[index].timeValue} ${this.paymentTerms[index].timeUnit}`
      if(this.paymentTerms[index].showSentence) {
        this.paymentTerms[index].showSentence = false
      }else {
        this.paymentTerms[index].showSentence = true
      }
    },
    async removePaymentTerm(index, paymentTerm) {
      this.paymentTerms.splice(index, 1)
    },
    addPaymentTerm() {
      let group = null
    if(this.paymentTerms.some(x => x.group)) {
      let groupFound = this.paymentTerms.find(term => term.group)
      group = groupFound.group
    }
    this.paymentTerms.length
      let paymentTerm = {
        showSentence: false,
        rank: this.paymentTerms.length + 1,
        amount: null,
        currency: this.company.currency,
        calculationType: 'FIXED',
        trigger: 'ETA',
        timeRelative: '+',
        timeValue: null,
        timeUnit: null,
        calculationValue: 0,
        sentence: false,
        group: group
      }
      this.paymentTerms.push(paymentTerm)
    },
    openEditPaymentTerm(group, index) {
      if(group) {
        this.paymentTerms.push(...group)
      } else {

        let paymentTerm = {
          showSentence: false,
          rank: 1,
          amount: null,
          currency: this.company.currency,
          calculationType: 'FIXED',
          trigger: 'ETA',
          timeRelative: '+',
          timeValue: null,
          timeUnit: null,
          sentence: false
        }
        this.paymentTerms.push(paymentTerm)
      }
      this.editPaymentTerms = true
    },
    closeEditPaymentTerm() {
      this.editPaymentTerms = false
      this.paymentTerms = []
    },
    async getAccountState(id){
      this.loadingAccountState = true
      let response = await this.$API.getAccountState(id) 
      this.company.accountsReceivableTotal = response.accountsReceivableTotal
      this.company.accountsReceivableOverdue = response.accountsReceivableOverdue
      this.company.accountsPayableTotal = response.accountsPayableTotal
      this.company.accountsPayableOverdue = response.accountsPayableOverdue
      this.company.totalCreditLimit = response.totalCreditLimit
      this.loadingAccountState = false
    },
    openAddCreditDialog() {
      this.editCreditLimit = {...this.editCreditLimitDefault}
      this.newCreditLimits = [...this.newCreditLimDefault]
      // this.showAddCreditLimitModal = true
      this.showEditCreditLimitModal = true
    },
    closeEditCreditLimit() {
      this.editCreditLimit = {}
      this.showEditCreditLimitModal = false
    },
    closeAddCreditLimit() {
      this.newCreditLimits = []
      this.showAddCreditLimitModal = false
      // console.log(this.newCreditLimits)
    },
    onPaymentTermMove() {
      this.paymentTerms.forEach((paymentTerm, index) => {
        if(paymentTerm) {
          paymentTerm.rank = index + 1
        }
      })
    },
    async onDragEnd() {
      try{
        this.loadingRanks = true
        let defArr = []
        for(const el of this.creditLimits) {
          if(el != undefined) {
            defArr.push(el)
          }
        }
        this.creditLimits = defArr
        // console.log(this.creditLimits.map((x) => x.rank))

        this.creditLimits.forEach((credit, index) => {
          if (credit) {
            credit.rank = index + 1;
          }
        });
        // Replace the original array with the safely updated one
        let ranks = await this.$API.updateCreditRanks(this.creditLimits)
        for(const rank of ranks) {
          let index = this.creditLimits.findIndex((x) => x.id === rank.id)
          this.creditLimits[index].rank = rank.rank
        }
        this.loadingRanks = false
      }catch(e) {
        console.log(e)
      }
    },
    async updateRanks(){
      this.loadingRanks = true
      this.creditLimits.forEach((credit, index) => {
          if (credit) {
            credit.rank = index + 1;
          }
        });
        let ranks = await this.$API.updateCreditRanks(this.creditLimits)
        for(const rank of ranks) {
          let index = this.creditLimits.findIndex((x) => x.id === rank.id)
          this.creditLimits[index].rank = rank.rank
        }
        this.loadingRanks = false
    },
    logItem(item) {
      console.log(item)
      
    },
    updateRank(credit, index, rank) {
      this.creditLimits[index].rank = rank;

      this.creditLimits.forEach((item, i) => {
        if (i !== index) {
          if (item.rank >= rank && i < index) {
            item.rank += 1;
          }
          if (item.rank <= rank && i > index) {
            item.rank -= 1;
          }
        }
      });
      this.creditLimits.sort((a, b) => a.rank - b.rank);
    },
    async saveWarningPercentage() {
    
    // localStorage.setItem('warningPercentage', this.warningPercentage);
    try{
      let response = await this.$API.updateCreditWarning(this.company)
      this.company.warningPercentage = response.warningPercentage
    }catch(e) {
      console.log(e)
    }

    // this.updateGaugeChart();
  },
  // updateGaugeChart() {
  //   this.$refs.gaugeChart.updateGauge(); 
  // },

    addAccountingContact() {
      let address =
        this.company.relatedOrganisation.organisationAddresses.find(
          (a) => a.isHQ
        ) ?? this.company.relatedOrganisation.organisationAddresses.length > 0
          ? this.company.relatedOrganisation.organisationAddresses[0]
          : null;
      this.accountingContact = {
        name: this.company.relatedOrganisation.name,
        accountReference: this.company.accountReference,
        currency: this.company.currency,
        taxNumber: this.company.relatedOrganisation.taxNumber,
        emailAddress: null,
        address: {}
      };
      if (address) {
        this.accountingContact.address = {
          addressType: address.postalCode ? "POBOX" : "STREET",
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          addressLine3: address.addressLine3,
          city: address.town,
          region: address.subdivision,
          postalCode: address.postalCode,
          country: address.country,
        };
      }
      this.accountingContactModal = true;
    },
    async getAccountingContacts() {
      this.accountingContacts = await this.$API.getAccountingContacts();
    },
    formatNumber(x, isCost = false) {
      if (isCost) {
        x *= -1;
      }
      if (x) {
        let result = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return result;
      } else {
        return null;
      }
    },
    getClientStatusColor(status) {
      switch (status) {
        case "GOOD STANDING":
          return "green";
        case "INACTIVE":
          return "red";
        case "ON HOLD":
          return "orange";
        case "COD":
          return "blue";
        default:
          return "grey";
      }
    },
    async getFinanceChargeItems() {
      this.financeChargeItems = await this.$API.getFinanceChargeItems();
    },
    getSymbol(currencyCode) {
      return getSymbolFromCurrency(currencyCode);
    },
    async loadBillingItems(id) {
      this.loadingBilling = true;
      this.billingItems = await this.$API.getRelationshipBillingItems(id);
      this.loadingBilling = false;
    },
    async saveAccountingContact() {
      this.savingAccountingContact = true;
      try {
        let contactResult = await this.$API.createAccountingContact(
          this.accountingContact
        );
        this.$message.success("Successfully Created!");
        if (contactResult && contactResult.id) {
          this.company.accountingId = contactResult.id;
          this.company.accountReference = contactResult.accountReference;
          this.company.accountsReceivableTotal =
            contactResult.accountsReceivableTotal;
          this.company.accountsPayableTotal =
            contactResult.accountsPayableTotal;
          this.company.accountsReceivableOverdue =
            contactResult.accountsReceivableOverdue;
          this.company.accountsPayableOverdue =
            contactResult.accountsPayableOverdue;
          this.accountingContacts.push(contactResult);
        }
        this.accountingContactModal = false;
        this.savingAccountingContact = false;
        this.saveRelationship();
      } catch (e) {
        if (e.message) {
          this.$message.error(e.message);
        } else {
          this.$message.error("An error occurred");
        }
        this.savingAccountingContact = false;
      }
    },
    async saveAccountingSetting(val) {
      if (val) {
        this.company.accountingId = val.id;
        this.company.accountReference = val.accountReference;
        this.company.accountsReceivableTotal = val.accountsReceivableTotal;
        this.company.accountsPayableTotal = val.accountsPayableTotal;
        this.company.accountsReceivableOverdue = val.accountsReceivableOverdue;
        this.company.accountsPayableOverdue = val.accountsPayableOverdue;
      } else {
        this.company.accountingId = null;
      }
      this.saveRelationship();
    },
    async saveRelationship() {
      this.savingRelationship = true;
      if (!this.company.isCustomer && !this.company.isSupplier) {
        this.company.isCustomer = true;
      }
      this.company.accountManagerId = this.company.accountManager
        ? this.company.accountManager.id
        : null;
      this.company.creditLimits = this.creditLimits; // Save the credit limits
      this.company.creditLimit = this.total; // Update company credit limit with the new total
      await this.$API.updateRelation(this.company);
      this.$message.success("Successfully Updated!");
      this.$emit("refresh", this.company);
      this.savingRelationship = false;
      this.editPaymentTerms = false;
      this.editAccountReference = false;
    },
    async updateClientStatus(status) {
      this.savingClientStatus = true;
      this.company.clientStatus = status;
      await this.saveRelationship();
      this.savingClientStatus = false;
    },
    addCreditLimit() {
      this.creditLimits.push({
        currency: this.company.currency,
        amount: 0,
        policyNumber: '',
        organisation: null,
        monthlyPremium: 0,
        rank: this.creditLimits.length + 1 // Assign a rank to each credit limit
      });
      this.calculateTotalCreditLimit();
    },

    async removeCreditLimit(index) {
      const creditLimit = this.creditLimits[index];

      try {
        await this.$API.deleteCreditLimit(creditLimit.id);
        this.creditLimits.splice(index, 1);
        this.updateRanks(); // Ensure ranks are updated correctly after removal
        this.calculateTotalCreditLimit();
        this.getAccountState(this.company.id)
        this.$message.success("Credit Limit Successfully Deleted!");
      } catch (error) {
        console.error("Error deleting credit limit:", error);
        this.$message.error("An error occurred while deleting credit limit.");
      }
    },
    
    async saveCreditLimits() {
      let credit = this.editCreditLimit
      if(credit.id) {
        try{
          let response = await this.$API.updateCreditLimits(credit)
          let oldCreditIndex = this.creditLimits.findIndex((x) => x.id === response.id)
          this.creditLimits[oldCreditIndex] = response
          this.showEditCreditLimitModal = false
          this.getAccountState(this.company.id)
          this.$message.success('Successfully updated credit')
          this.editCreditLimit = {}
        }catch  (e) {
          this.$message.error(e.message)
        }
      }else {
        let currentRanks = this.creditLimits.length
        // const preparedCreditLimits = this.newCreditLimits.map((credit, index) => ({
        //   limit: credit.limit,
        //   creditCurrency: credit.currency,
        //   policyNumber: credit.policyNumber,
        //   creditProviderRelationshipId: credit.creditProviderRelationshipId, 
        //   monthlyPremium: credit.monthlyPremium,
        //   rank: currentRanks + index + 1,
        //   calculationType: credit.calculationType,
        //   premiumPercentage: credit.premiumPercentage ? credit.premiumPercentage : null
        // }));
        let preparedCreditLimit = {...this.editCreditLimit, rank: currentRanks + 1,}
        // console.log('preparedCreditLimits')
        // console.log(preparedCreditLimits)
        // console.log(this.editCreditLimit)
        // let creditArr = []
        // creditArr.push(credit)
        try{
          let response = await this.$API.createCreditLimits(this.company.id, [preparedCreditLimit])

          this.getAccountState(this.company.id)
          this.creditLimits.push(...response)
          this.showAddCreditLimitModal = false
          this.newCreditLimits = []
          this.editCreditLimit = {}
          this.showEditCreditLimitModal = false
          this.$message.success('Successfully created credit')

        }catch  (e) {
          this.$message.error(e.message)
        }
      }
      this.saveRelationship();
      this.updateCreditLimitModal = false;
      this.calculateTotalCreditLimit();
    },
    addNewCreditLimit() {
      this.newCreditLimits.push({ currency: "", amount: 0, policyNumber: "", organisation: null, rank: null });
      this.calculateTotalCreditLimit();
    },
    removeNewCreditLimit(index) {
      this.newCreditLimits.splice(index, 1);
      this.calculateTotalCreditLimit();
    },
    async fetchOrganisations() {
      try {
        const response = await this.$API.getRelationBasic();
        this.organisations = response.data.map(org => ({
          id: org.id,
          name: org.relatedOrganisation.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    onOrganisationChange(index, organisation) {
      this.newCreditLimits[index].organisation = this.organisations.find(org => org.name === organisation);
      this.calculateTotalCreditLimit();
    },
    async createCreditLimits() {
      try {
        const preparedCreditLimits = this.newCreditLimits.map(credit => ({
          limit: credit.limit,
          creditCurrency: credit.currency,
          policyNumber: credit.policyNumber,
          creditProviderRelationshipId: credit.creditProviderRelationshipId, 
          monthlyPremium: credit.monthlyPremium
        }));

        const response = await this.$API.createCreditLimits(this.company.id, preparedCreditLimits);

        this.creditLimits.push(...response);
        this.newCreditLimits = [{ currency: "", limit: 0, policyNumber: "", organisation: null, monthlyPremium: 0 }]; // Reset the new credit limits array
        this.showAddCreditLimitModal = false; // Close the modal
        this.$message.success("Credit Limits Successfully Created!");
        this.calculateTotalCreditLimit();
      } catch (e) {
        console.error("Error occurred while creating credit limits:", e);
        this.$message.error("An error occurred while saving credit limits.");
      }
    },
    saveNewCreditLimits() {
      this.createCreditLimits();
    },
    async getCreditLimits(id) {
      try {
        const response = await this.$API.getCreditLimits(id);
        this.creditLimits = response;
        this.sortCreditLimits();
        // this.calculateTotalCreditLimit();
      } catch (e) {
        console.error("Error occurred while fetching credit limits:", e);
        // this.$message.error("An error occurred while fetching credit limits.");
      }
    },
    calculateTotalCreditLimit() {
      const totals = {};

      this.creditLimits.forEach(credit => {
        if (!totals[credit.creditCurrency]) {
          totals[credit.creditCurrency] = 0;
        }
        totals[credit.creditCurrency] += parseFloat(credit.limit || 0);
      });

      this.newCreditLimits.forEach(credit => {
        if (!totals[credit.currency]) {
          totals[credit.currency] = 0;
        }
        totals[credit.currency] += parseFloat(credit.limit || 0);
      });

      this.totalCreditLimits = totals;

      const desiredCurrency = this.company.creditLimitCurrency;
      this.total = totals[desiredCurrency] || 0;
      this.company.creditLimit = this.total;
      // this.saveRelationship();
    },
    sortCreditLimits() {
      this.creditLimits.sort((a, b) => a.rank - b.rank);
    },
    addSelfGuarantorLimit() {
      this.selfGuarantorLimits.push({
        currency: this.company.currency,
        limit: 0,
        policyNumber: "",
        organisation: this.$store.state.currentOrg.name,
        rank: null
      });
      this.calculateTotalCreditLimit();
    },
    removeSelfGuarantorLimit(index) {
      this.selfGuarantorLimits.splice(index, 1);
      this.calculateTotalCreditLimit();
    },
    async saveSelfGuarantorLimits() {
      try {
        const preparedCreditLimits = this.selfGuarantorLimits.map(credit => ({
          limit: credit.limit,
          creditCurrency: credit.currency,
          policyNumber: credit.policyNumber,
          organisation: credit.organisation,
          monthlyPremium: credit.monthlyPremium
        }));

        const response = await this.$API.createCreditLimits(this.company.id, preparedCreditLimits);
        this.creditLimits.push(...response);
        this.selfGuarantorLimits = [{ currency: "", amount: 0, policyNumber: "", organisation: this.$store.state.currentOrg.name, rank: null }];
        this.showSelfGuarantorModal = false;
        this.$message.success("Credit Limits Successfully Created!");
        this.calculateTotalCreditLimit();
      } catch (e) {
        console.error("Error occurred while creating credit limits:", e);
        this.$message.error("An error occurred while saving credit limits.");
      }
    },
    openEditCreditLimitModal(credit, index) {
      this.editCreditLimit = credit;
      this.editIndex = index;
      this.showEditCreditLimitModal = true;
    },
    async saveEditedCreditLimit() {
      if (this.editIndex !== null) {
        try {
          const originalCreditLimit = this.creditLimits[this.editIndex];
          const updatedCreditLimit = { ...this.editCreditLimit };

          const changes = {};
          for (const key in updatedCreditLimit) {
            if (updatedCreditLimit[key] !== originalCreditLimit[key]) {
              changes[key] = updatedCreditLimit[key];
            }
          }

          changes.id = updatedCreditLimit.id;

          if (Object.keys(changes).length > 1) {
            // console.log("Changes to be updated:", changes);

            await this.$API.updateCreditLimits(changes);

            this.creditLimits.splice(this.editIndex, 1, updatedCreditLimit);

            await this.getCreditLimits(this.company.id);

            this.$message.success("Credit Limit Successfully Updated!");
          } else {
            this.$message.info("No changes detected.");
          }

          this.showEditCreditLimitModal = false;
        } catch (error) {
          console.error("Error updating credit limit:", error);
          this.$message.error("An error occurred while updating credit limit.");
        }
      }
    }
  },
};
</script>


<style scoped>
.gauge-container {
  padding-top: 10px; 
  margin-bottom: -20px; /* Adjust the bottom margin to move it up */
  margin-top: 1; /* Adjust the top margin to move it up */
  display: flex;
  justify-content: center; /* Center the gauge horizontally */
  align-items: center; /* Center the gauge vertically */
}
.chart {
  height: 65vh; 
  width: 100%; 
  max-width: 600px; /* Set a max-width for larger screens */
  margin: 0 auto; /* Center the chart within the container */
}

@media (max-width: 1200px) {
  .chart {
    max-width: 500px; 
  }
}

@media (max-width: 900px) {
  .chart {
    max-width: 400px; 
  }
}

@media (max-width: 768px) {
  .chart {
    max-width: 300px; 
  }
}

@media (max-width: 600px) {
  .chart {
    max-width: 250px; 
  }
}

@media (max-width: 480px) {
  .chart {
    max-width: 200px; 
  }
}

.transparent-button {
  background-color: transparent !important;
  color: var(--v-primary-base) !important;
  /* color: #ffffff !important; White text color */
  box-shadow: none !important;
  margin-right: 10px; /* Add some spacing between buttons */
  margin-top: 20px;
  margin-bottom: 20px;
}

.transparent-button:hover {
  background-color: rgba(255, 255, 255, 0.1) !important; 
}

.outlined-button {
  background-color: transparent !important;
  color: var(--v-primary-base) !important;
  /* color: #ffffff !important; White text color */
  box-shadow: none !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

.outlined-button:hover {
  background-color: rgba(255, 255, 255, 0.1) !important; 
}
.drag-handle {
  cursor: move;
}
.payment-handle {
  cursor: move;
}
.clickable{
  cursor: pointer;
}

</style>